export default {
  routes: [
    {
      path: "details",
      name: "corrective-details",
      component: () => import("./ShowCorrectiveDetails.vue"),
      meta: {
        roles: [""],
        permissions: ["can_view_corrective_work_orders"],
      },
    },
    {
      path: "corrective-activity-logs",
      name: "corrective-activity-logs",
      component: () => import("./ShowCorrectiveActivity.vue"),
      meta: {
        roles: [""],
        permissions: ["can_view_corrective_work_orders"],
      },
    },
    {
      path: "procedure",
      name: "corrective-procedure",
      component: () => import("./ShowCorrectiveProcedure.vue"),
      meta: {
        roles: [""],
        permissions: ["can_view_corrective_work_orders"],
      },
    },
    {
      path: "tasks",
      name: "corrective-tasks",
      component: () => import("./ShowCorrectiveTaskList.vue"),
      meta: {
        roles: [""],
        permissions: ["can_view_corrective_work_orders"],
      },
    },
    {
      path: "issued-payment",
      name: "corrective-payment",
      component: () => import("./ShowCorrectiveIssuedPayment.vue"),
      meta: {
        roles: [""],
        permissions: ["can_view_corrective_work_orders"],
      },
    },
    {
      path: "invoice",
      name: "corrective-invoice",
      component: () => import("../../Shared/InvoicesList.vue"),
      meta: {
        roles: [""],
        type: "corrective",
      },
    },
    {
      path: "permit",
      name: "corrective-permit-list",
      component: () => import("../ShowCorrective/permit/PermitList.vue"),
      meta: {
        permissions: [
          "can_view_corrective_work_orders",
          "can_access_work_permit_template",
        ],
        type: "corrective",
      },
    },
    {
      path: "rate",
      name: "corrective-rate",
      component: () => import("./components/Rating/RateList.vue"),
      meta: {
        roles: [""],
        permissions: ["can_view_corrective_work_orders"],
      },
    },
  ],
};
