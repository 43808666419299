export default {
  routes: [
    {
      path: "/residents",
      name: "residents",
      redirect: "/",
      meta: {
        title: "user_management.unit_occupants",
        roles: [""],
        requiredModules: ["add-occupants"],
      },
      children: [
        {
          path: "",
          name: "residents-list",
          component: () => import("./UnitResidentsList.vue"),
          meta: {
            roles: [""],
            permissions: [
              "can_access_unit_residents",
              "can_list_unit_residents",
            ],
          },
        },
        // {
        //   path: "create",
        //   name: "create-resident",
        //   component: () => import("./NewUnitResident.vue"),
        //   meta: {
        //     roles: [""],
        //     permissions: ["can_create_unit_residents"],
        //   },
        // },
        {
          path: "create",
          name: "create-resident",
          component: () => import("./create/UnitCreationWrapper.vue"),
          meta: {
            roles: [""],
            permissions: ["can_create_unit_residents"],
          },
        },
        {
          path: "success/:id",
          name: "resident_creation_success",
          component: () => import("./create/SuccessCreation.vue"),
          meta: {
            roles: [""],
            permissions: ["can_create_unit_residents"],
          },
        },
        {
          path: "success-agreement/:id",
          name: "resident_agreement_success",
          props: true,
          component: () =>
            import("./edit/Agreements/SuccessAgreementCreation.vue"),
          meta: {
            roles: [""],
            permissions: ["can_update_unit_residents"],
          },
        },
        // Agreements
        {
          path: ":id/agreements/:agreementId",
          name: "create_agreements",
          props: true,
          component: () => import("./edit/Agreements/AgreementsWrapper.vue"),
          meta: {
            roles: [""],
            permissions: ["can_update_unit_residents"],
          },
        },
        {
          path: ":id/agreements-locations",
          name: "create_agreements_locations",
          props: true,
          component: () => import("./edit/Agreements/AgreementsWrapper.vue"),
          meta: {
            roles: [""],
            permissions: ["can_update_unit_residents"],
            isDifferentLocation: true,
          },
        },
        {
          path: ":id/edit",
          name: "edit-resident",
          props: true,
          component: () => import("./edit/WrapperEditInfo.vue"),
          meta: {
            roles: [""],
            permissions: ["can_update_unit_residents"],
            isEdit: true,
          },
        },
        {
          path: ":id",
          name: "resident-details",
          props: true,
          component: () => import("./details/ResidentDetailsWrapper.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_unit_residents"],
          },
          redirect: { name: "resident-info" },
          children: [
            {
              path: "details",
              name: "resident-info",
              props: true,
              component: () => import("./details/ResidentInfo.vue"),
              meta: {
                permissions: ["can_view_unit_residents"],
              },
            },
            {
              path: "payments",
              name: "resident-payments",
              props: true,
              component: () => import("./details/ResidentPayments.vue"),
              meta: {
                permissions: ["can_view_unit_residents"],
                requiredModules: ["occupant-request-payment"],
              },
            },
            {
              path: "resident-logs",
              name: "resident-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              meta: {
                roles: [""],
                permissions: ["can_view_unit_residents"],
              },
            },
          ],
        },
      ],
    },
  ],
};
