import { getAccessibleRedirectRoute } from "@/helpers/redirect.helper";

export default {
  routes: [
    {
      path: "/work-permit",
      name: "work-permit-index",
      component: () => import("./WrapperWorkPermit.vue"),
      // redirect: { name: "work-permit-template" },
      redirect: (to) => {
        const parentRoute = to.matched.find(
          (route) => route.name === "work-permit-index",
        );
        const childRoutes = parentRoute.children || [];
        return getAccessibleRedirectRoute(childRoutes, "permissions");
      },
      meta: {
        title: "library.work_permit",
        requiredModules: ["work-permits"],
      },
      children: [
        {
          path: "template",
          name: "work-permit-template",
          component: () => import("./PermitTemplate.vue"),
          meta: {
            permissions: ["can_access_work_permit_template"],
          },
        },
        {
          path: "list",
          name: "work-permit-list",
          component: () => import("./PermitList.vue"),
          meta: {
            permissions: ["can_access_work_permit_template"],
          },
        },
        // {
        //   path: "edit/:id",
        //   name: "edit-template",
        //   component: () => import("./EditTemplate.vue"),
        //   meta: {
        //     permissions: ["can_update_work_order_template"],
        //   },
        // },
      ],
    },
  ],
};
