export default {
  routes: [
    {
      path: "details",
      name: "vendor-ppm-details",
      component: () => import("./ShowPPMDetails.vue"),
      meta: {
        title: "detail",
        roles: [""],
      },
    },
    {
      path: "activity-logs",
      name: "vendor-activity-logs",
      component: () => import("./ShowPPMActivity.vue"),
      meta: {
        title: "detail",
        roles: [""],
      },
    },
    {
      path: "procedure",
      name: "vendor-procedure",
      component: () => import("./ShowPPMProcedure.vue"),
      meta: {
        title: "detail",
        roles: [""],
      },
    },
    {
      path: "tasks",
      name: "vendor-ppm-tasks",
      component: () => import("./ShowPPMTaskList.vue"),
      meta: {
        title: "detail",
        roles: [""],
      },
    },
    {
      path: "permits",
      name: "vendor-ppm-permits",
      component: () =>
        import(
          "@/views/Dashboard/WorkOrders/CorrectiveOrders/ShowCorrective/permit/PermitList.vue"
        ),
      meta: {
        title: "detail",
        roles: [""],
        name: "ppm",
        usePpmStore: true,
      },
    },
    {
      path: "vendor-ppm-invoice",
      name: "vendor-ppm-invoice",
      component: () => import("../../Shared/InvoicesList.vue"),
      meta: {
        title: "detail",
        roles: [""],
        type: "ppm",
      },
    },
    {
      path: "rate",
      name: "vendor-ppm-rate",
      component: () => import("../../Shared/RateList.vue"),
      meta: {
        title: "detail",
        roles: [""],
        type: "ppm",
      },
    },
  ],
};
