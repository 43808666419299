export default {
  routes: [
    {
      path: "/public-links",
      name: "public_links",
      component: () => import("./PublicLinksIndex.vue"),
      meta: {
        title: "requests.public_links",
        permissions: ["can_access_public_url", "can_list_public_url"],
        requiredModules: ["requests"],
      },
      redirect: { name: "public_links_index" },
      children: [
        {
          path: "",
          name: "public_links_index",
          component: () => import("./WrapperPublicLinks.vue"),
          meta: {
            roles: [""],
            permissions: ["can_access_public_url", "can_list_public_url"],
          },
        },
        {
          path: "create/:id",
          name: "create_public_links",
          component: () => import("./CreatePublicLink.vue"),
          meta: {
            roles: [""],
            permissions: ["can_create_public_url"],
          },
        },
        {
          path: "edit/:id",
          name: "edit_public_links",
          component: () => import("./CreatePublicLink.vue"),
          meta: {
            isEdit: true,
            roles: [""],
            permissions: ["can_edit_public_url"],
          },
        },
        {
          path: "show/:id",
          component: () => import("./details/WrapperShowPublicLink.vue"),
          name: "show_public_links",
          redirect: "public_links_details",
          meta: {
            roles: [""],
            permissions: ["can_view_public_url"],
          },
          children: [
            {
              path: "details",
              name: "public_links_details",
              component: () => import("./details/PublicLinksDetails.vue"),
              meta: {
                roles: [""],
              },
            },
            {
              path: "submitted-requests",
              name: "public_links_submitted_requests",
              component: () => import("./details/SubmittedRequests.vue"),
              meta: {
                roles: [""],
              },
            },
          ],
        },
        {
          path: ":id/submitted-details/:submittedId",
          name: "public_links_submitted_details",
          component: () => import("./details/SubmittedRequestsDetails.vue"),
          meta: {
            roles: [""],
          },
        },
      ],
    },
  ],
};
