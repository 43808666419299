<template>
  <!-- <div v-if="isLoading" class="h-dvh flex items-center justify-center">
    <mc-loader />
  </div> -->
  <!-- <template > -->
  <Toast />
  <router-view />
  <!-- </template> -->
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useAuthStore } from "@/stores/auth/auth.store";
import { useThemeStore } from "@/stores/connect/theme/theme.store.js";
export default {
  mounted() {
    // await this.checkAuth();
    // const defColor = this.settings?.system_color || "#007bff";
    // await this.changeThemeColor(defColor);
    const lang = localStorage.getItem("mc-local") || "ar";
    this.$i18n.locale = document.documentElement.lang = lang;
    document.documentElement.dir = this.$i18n.locale == "ar" ? "rtl" : "ltr";
  },
  computed: {
    ...mapState(useAuthStore, ["settings", "isLoading"]),
  },

  methods: {
    ...mapActions(useAuthStore, ["checkAuth"]),
    ...mapActions(useThemeStore, ["changeThemeColor"]),
  },
};
</script>

<style lang="scss">
// set font
.ql-editor li[data-list="bullet"]::before {
  width: 5px;
  height: 5px;
  background: #000;
  border-radius: 50%;
  content: "";
}

// scroll bar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 2px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.fade-leave-to {
  opacity: 0;
  transform: translateX(-30px);
  display: none;
  height: 0;
}
</style>
