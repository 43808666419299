export default {
  routes: [
    {
      path: "details",
      name: "ppm-details",
      component: () =>
        import(
          "../../CorrectiveOrders/ShowCorrective/ShowCorrectiveDetails.vue"
        ),
      meta: {
        title: "work_orders.ppm",
        roles: [""],
        permissions: ["can_view_ppm_work_orders"],
        name: "ppm",
      },
    },
    {
      path: "ppm-activity-logs",
      name: "ppm-activity-logs",
      component: () =>
        import(
          "../../CorrectiveOrders/ShowCorrective/ShowCorrectiveActivity.vue"
        ),
      meta: {
        title: "work_orders.ppm",
        roles: [""],
        permissions: ["can_view_ppm_work_orders"],
        name: "ppm",
      },
    },
    {
      path: "procedure",
      name: "ppm-procedure",
      component: () =>
        import(
          "../../CorrectiveOrders/ShowCorrective/ShowCorrectiveProcedure.vue"
        ),
      meta: {
        title: "work_orders.ppm",
        roles: [""],
        permissions: ["can_view_ppm_work_orders"],
        name: "ppm",
      },
    },
    {
      path: "tasks",
      name: "ppm-tasks",
      component: () =>
        import(
          "../../CorrectiveOrders/ShowCorrective/ShowCorrectiveTaskList.vue"
        ),
      meta: {
        title: "work_orders.ppm",
        roles: [""],
        permissions: ["can_view_ppm_work_orders"],
        name: "ppm",
      },
    },
    {
      path: "permit",
      name: "ppm-permit-list",
      component: () =>
        import("../../CorrectiveOrders/ShowCorrective/permit/PermitList.vue"),
      meta: {
        permissions: [
          "can_view_ppm_work_orders",
          "can_access_work_permit_template",
        ],
        name: "ppm",
      },
    },
    {
      path: "invoice",
      name: "ppm-invoice",
      component: () => import("../../Shared/InvoicesList.vue"),
      meta: {
        roles: [""],
        name: "ppm",
      },
    },
    {
      path: "rate",
      name: "ppm-rate",
      component: () =>
        import(
          "../../CorrectiveOrders/ShowCorrective/components/Rating/RateList.vue"
        ),
      meta: {
        roles: [""],
        name: "ppm",
        permissions: ["can_view_ppm_work_orders"],
      },
    },
  ],
};
