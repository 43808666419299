export default {
  routes: [
    {
      path: "/requests",
      name: "requests",
      component: () => import("./RequestsIndex.vue"),
      meta: {
        title: "requests.requests",
        roles: [""],
        permissions: ["can_access_requests", "can_list_requests"],
        requiredModules: ["requests"],
      },
      children: [
        {
          path: "",
          name: "requests-index",
          component: () => import("./WrapperRequests.vue"),
          meta: {
            roles: [""],
            permissions: ["can_access_requests", "can_list_requests"],
          },
          redirect: { name: "requests-list-view" },

          children: [
            {
              path: "list",
              name: "requests-list-view",
              component: () => import("./RequestsList.vue"),
              meta: {
                roles: [""],
                permissions: ["can_access_requests", "can_list_requests"],
              },
            },
            {
              path: "board",
              name: "requests-board-view",
              component: () => import("./RequestsBoard.vue"),
              meta: {
                roles: [""],
                permissions: ["can_access_requests", "can_list_requests"],
              },
            },
          ],
        },
        {
          path: "create",
          name: "requests-create",
          component: () => import("./CreateSnapRequests.vue"),
          meta: {
            roles: [""],
            permissions: ["can_create_requests"],
          },
        },
        {
          path: "edit/:id",
          name: "requests-edit",
          component: () => import("./CreateSnapRequests.vue"),
          meta: {
            roles: [""],
            isEdit: true,
            permissions: ["can_update_requests"],
          },
        },
        {
          path: ":id",
          name: "requests-view",
          component: () => import("./WrapperShowRequest.vue"),
          redirect: { name: "request-details" },
          meta: {
            roles: [""],
            permissions: ["can_view_requests"],
          },
          children: [
            {
              path: "details",
              name: "request-details",
              component: () => import("./ShowRequest.vue"),
              meta: {
                roles: [""],
                permissions: ["can_view_requests"],
              },
            },
            {
              path: "request-logs",
              name: "request-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              meta: {
                roles: [""],
                permissions: ["can_view_requests"],
              },
            },
          ],
        },
      ],
    },
  ],
};
