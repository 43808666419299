export default {
  routes: [
    {
      path: ":id",
      name: "show-unit",
      component: () => import("./WrapperShowUnit.vue"),
      meta: {
        roles: [""],
        permissions: ["can_view_units"],
      },
      redirect: { name: "show-unit-details" },
      children: [
        {
          path: "details",
          name: "show-unit-details",
          component: () => import("./ShowUnitDetails.vue"),
          meta: {
            permissions: ["can_view_units"],
            roles: [""],
          },
        },
        {
          path: "customer-history",
          name: "show-unit-customer-history",
          component: () => import("./ShowUnitCustomerHistory.vue"),
          meta: {
            permissions: ["can_view_units"],
            roles: [""],
          },
        },
        {
          path: "assets",
          name: "unit-assets",
          component: () =>
            import("@/views/Dashboard/Locations/shared/LocationAssetsList.vue"),
          meta: {
            locationType: "unit",
            permissions: ["can_view_units"],
          },
        },
        {
          path: "unit-logs",
          name: "unit-logs",
          component: () => import("@/components/ui/ActivityLog.vue"),
          meta: {
            roles: [""],
            permissions: ["can_view_units"],
          },
        },
      ],
    },
  ],
};
