const formatNumberWithCommas = (number = 0) => {
  // Handle null/undefined/empty values
  if (number === null || number === undefined || number === "") return "---";

  // Remove commas and attempt conversion to a valid number
  const sanitizedNumber = String(number).replace(/,/g, "");
  const parsedNumber = Number(sanitizedNumber);

  // Return "---" for invalid numbers
  if (isNaN(parsedNumber)) return "---";

  // Format with commas and 2 decimal places
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parsedNumber);

  // // Convert to string and split into integer and decimal parts
  // const parts = number.toString().split(".");
  // // let integerPart = parts[0];
  // // const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";
  // console.log(parts);
  // // Convert to fixed 2 decimal places and split into integer and decimal parts
  // let [integerPart, decimalPart] = Number(number).toFixed(2).split(".");

  // // Add commas to integer part
  // integerPart = integerPart
  //   .split("")
  //   .reverse()
  //   .join("")
  //   .match(/\d{1,3}/g)
  //   .join(",")
  //   .split("")
  //   .reverse()
  //   .join("");

  // // return integerPart + decimalPart;
  // return `${integerPart}.${decimalPart}`;
};

export default formatNumberWithCommas;
