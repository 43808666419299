import pricebookRoute from "./Pricebook/pricebook.route";
import proceduresRoute from "./Procedures/procedures.route";
import workOrderTemplateRoute from "./WorkOrderTemplate/work.order.template.route";
import workPermitRoute from "./WorkPermit/work.permit.route";

export default {
  routes: [
    {
      path: "library",
      component: () => import("./WrapperLibrary.vue"),
      redirect: { name: "pricebook" },
      children: [
        ...pricebookRoute.routes,
        ...proceduresRoute.routes,
        ...workOrderTemplateRoute.routes,
        ...workPermitRoute.routes,
      ],
    },
  ],
};
