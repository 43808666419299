export default {
  routes: [
    {
      path: "auto-assign",
      name: "auto-assign-wrapper",
      redirect: { name: "auto-assign-list" },
      meta: {
        requiredModules: null,
      },
      children: [
        {
          path: "",
          name: "auto-assign-list",
          component: () => import("./AutoAssignList.vue"),
          meta: {
            permissions: ["can_access_auto_assigns", "can_list_auto_assigns"],
          },
        },
        {
          path: "create",
          name: "create-auto-assign",
          component: () => import("./CreateAutoAssign.vue"),
          meta: {
            permissions: ["can_access_auto_assigns", "can_create_auto_assigns"],
          },
        },
        {
          path: ":id/edit",
          name: "auto-assign-update",
          component: () => import("./CreateAutoAssign.vue"),
          meta: {
            isEdit: true,
            permissions: ["can_access_auto_assigns", "can_edit_auto_assigns"],
          },
        },
        {
          path: ":id",
          component: () => import("./details/AutoAssignDetailsWrapper.vue"),
          name: "auto-assign-details-wrapper",
          redirect: "details",
          props: true,
          meta: {
            permissions: ["can_access_auto_assigns", "can_view_auto_assigns"],
          },
          children: [
            {
              path: "details",
              name: "auto-assign-details",
              component: () => import("./details/AutoAssignInfo.vue"),
              meta: {
                permissions: [
                  "can_access_auto_assigns",
                  "can_view_auto_assigns",
                ],
              },
            },
            {
              path: "auto-assign-logs",
              name: "auto-assign-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              meta: {
                permissions: [
                  "can_access_auto_assigns",
                  "can_view_auto_assigns",
                ],
              },
            },
          ],
        },
      ],
    },
  ],
};
