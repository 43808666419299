// http client helper
import axios from "axios";
import { useToastService } from "./toast.helper";
import { extractKeyword } from "./url.helper";
import router from "../router";
import i18n from "../plugins/I18n";
const lang = localStorage.getItem("mc-local") || "en";
const { showToast } = useToastService();
import { useAuthStore } from "@/stores/auth/auth.store";

const URL = import.meta.env.VITE_BASE_API_URL;
const axiosClient = axios.create({
  baseURL: URL,
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "request-source": extractKeyword(window.location.href),
    "Accept-Language": lang,
  },
});

axiosClient.interceptors.request.use((config) => {
  const token = window.$cookies.get(
    `${extractKeyword(window.location.href)}_mc_token`,
  );
  const authStore = useAuthStore();
  const companiesAccount = authStore.getCompanies();
  const portalName = extractKeyword(window.location.href);
  if (portalName === "connect") {
    // if (authStore && companiesAccount) {
    const defaultCompany =
      companiesAccount && companiesAccount.length
        ? companiesAccount?.find((company) => company.is_default)
        : undefined;
    // the company user are show his data , and not the same default company
    const currentCompanyId = localStorage.getItem("connect-company");
    if (currentCompanyId || defaultCompany) {
      config.headers["connect-company"] =
        currentCompanyId || defaultCompany.id || undefined;
    }
    // }
  }

  if (!config.baseURL.includes("moyasar")) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// if 401 remove cookie and redirect to login
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    const errorInfo = error?.response?.data;
    const isSubscriptionFeatureError = errorInfo?.hasOwnProperty(
      "is_subscription_feature_error",
    )
      ? errorInfo.is_subscription_feature_error
      : false;
    const toastTitle = errorInfo?.hasOwnProperty("popup-title")
      ? errorInfo["popup-title"]
      : i18n.global.t(`global.massages.${errorInfo.severity}_message`);
    // Error message
    let errorMessage = errorInfo?.message || errorInfo?.data?.message;
    // Unauthorized error
    if (error.response.status == 401) {
      window.$cookies.remove(`${extractKeyword(window.location.href)}_mc_user`);
      window.$cookies.remove(
        `${extractKeyword(window.location.href)}_mc_companies`,
      );
      window.$cookies.remove(
        `${extractKeyword(window.location.href)}_mc_token`,
      );
      router.push({ name: "login" });
      return Promise.reject(error);
    }
    // Don`t Show the error message if it's a fatal error
    if (errorInfo.severity === "fatal-error") {
      showToast(toastTitle, errorMessage, "error");
      return Promise.reject(error);
    }

    //Only show error messages if it's not a subscription feature error
    if (!isSubscriptionFeatureError) {
      showToast(toastTitle, errorMessage, errorInfo.severity);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);

export default axiosClient;
