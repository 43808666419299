import { useAuthStore } from "@/stores/auth/auth.store";

export function getAccessibleRedirectRoute(
  childRoutes,
  mode = "modules",
  defaultRoute = "upgrade-plan",
) {
  const authStore = useAuthStore();
  const availableModules = authStore.planFeatures || [];
  const userPermissions = authStore.permissions || [];
  // Find the first accessible route, respecting the order in the array [availableModules]
  const accessibleRoute = childRoutes.find((route) => {
    return mode == "modules"
      ? redirectBasedOnModules(route, availableModules)
      : redirectBasedOnPermissions(route, userPermissions);
  });

  // Return the route name or fallback
  return { name: accessibleRoute?.name || defaultRoute };
}

function redirectBasedOnModules(route, availableModules) {
  const requiredModules = route.meta?.requiredModules || [];
  const isAccessible =
    requiredModules.length === 0 ||
    requiredModules.some((module) => availableModules.includes(module));
  return isAccessible;
}

function redirectBasedOnPermissions(route, userPermissions) {
  const routePermits = route.meta?.permissions || [];
  const isAccessible =
    routePermits.length !== 0 &&
    routePermits.some((routePermit) =>
      userPermissions.find((perm) => perm == routePermit),
    );
  return isAccessible;
}
