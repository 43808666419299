export default {
  routes: [
    {
      path: "approval-workflows",
      name: "approval-workflows-wrapper",
      redirect: { name: "approval-workflows-list" },
      meta: {
        requiredModules: null,
      },
      children: [
        {
          path: "",
          name: "approval-workflows-list",
          component: () => import("./ApprovalWorkflowsList.vue"),
          meta: {
            permissions: [
              "can_access_approval_workflows",
              "can_list_approval_workflows",
            ],
          },
        },
        {
          path: "create",
          name: "create-approval-workflow",
          component: () => import("./CreateApprovalWorkflow.vue"),
          meta: {
            permissions: [
              "can_access_approval_workflows",
              "can_create_approval_workflows",
            ],
          },
        },
        {
          path: ":id/edit",
          name: "approval-update",
          component: () => import("./CreateApprovalWorkflow.vue"),
          meta: {
            isEdit: true,
            permissions: [
              "can_access_approval_workflows",
              "can_edit_approval_workflows",
            ],
          },
        },
        {
          path: ":id",
          component: () =>
            import("./details/ApprovalWorkflowDetailsWrapper.vue"),
          name: "approval-workflow-details-wrapper",
          redirect: "details",
          props: true,
          meta: {
            permissions: [
              "can_access_approval_workflows",
              "can_view_approval_workflows",
            ],
          },
          children: [
            {
              path: "details",
              name: "approval-workflow-details",
              component: () => import("./details/ApprovalWorkflowInfo.vue"),
              meta: {
                permissions: [
                  "can_access_approval_workflows",
                  "can_view_approval_workflows",
                ],
              },
            },
            {
              path: "approval-workflow-logs",
              name: "approval-workflow-logs",
              component: () => import("@/components/ui/ActivityLog.vue"),
              meta: {
                permissions: [
                  "can_access_approval_workflows",
                  "can_view_approval_workflows",
                ],
              },
            },
          ],
        },
      ],
    },
  ],
};
