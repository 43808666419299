import { createI18n } from "vue-i18n";

// Transitions Language
import ar from "@/locale/ar";
import en from "@/locale/en";
const messages = {
  en,
  ar,
};

function handleArabicPluralization(choice, choicesLength) {
  // 0 | 1 | 2 | 3 | 4
  //  لا يوجد | مفرد | مثنى | (عدد) جمع | (عدد) مفرد
  // => if you need use pipe annotation must write 5 states of word separated with |

  if ([0, 1, 2].includes(choice)) return choice;

  return choice <= 10 ? 3 : choicesLength > 3 ? 4 : 3;
}

// install I18n
const i18n = new createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  globalInjection: true,
  pluralRules: {
    ar: handleArabicPluralization,
  },
  messages,
});

export default i18n;
